import { useSelector } from 'react-redux';

import { EGender, EGenderType } from '@constants/gender';
import { IWrappedCategoryMapped, IWrappedCategorySectionMapped } from '@interfaces/category';
import { IMegaMenuCategory, IMegaMenuLinkDataObject } from '@interfaces/megaMenu';
import { EProductsListFilter } from '@interfaces/productsList';
import { mapGenderIdToType, mapGenderTypeToBrandType } from '@utils/gender';

import { IImportantMenu, IImportantMenuItem, IPageDataReducer, IPageReference } from '@interfaces/pageData/reducer';
import { IStore } from '@interfaces/store';
import { addParamsToUrl } from '../../services/SSRService/utils';

export interface IMegaMenuDataSection {
  brands: any[];
  categories: IMegaMenuCategory[];
  trending: IMegaMenuCategory[];
}

interface IMegaMenuData {
  [EGender.man]: IMegaMenuDataSection;
  [EGender.women]: IMegaMenuDataSection;
  [EGender.girl]: IMegaMenuDataSection;
  [EGender.boy]: IMegaMenuDataSection;
  kids: IMegaMenuDataSection;
}

interface IUsePageData extends IPageDataReducer {
  megaMenu: IMegaMenuData;
}

export const usePageDataStore = (): IUsePageData => {
  const pageData: IPageDataReducer = useSelector((state: IStore) => state.pageData);
  const pages: IPageReference[] = useSelector((state: IStore) => state.pageData.data.pages || []);
  const { categoryWrapped } = pageData;
  const megaMenuStrapi = pageData?.data['mega-menu'] || null;
  const importantMenu: IImportantMenu | undefined = pageData?.data['important-menu'];

  return {
    ...pageData,
    megaMenu: mapMegaMenu(categoryWrapped, megaMenuStrapi, importantMenu, pages),
  };
};

export const useMegaMenu = (): IMegaMenuData => {
  const categoryWrapped = useSelector((state: IStore) => state.pageData.categoryWrapped);
  const pages: IPageReference[] = useSelector((state: IStore) => state.pageData.data.pages || []);
  const megaMenuStrapi: IMegaMenuLinkDataObject[] | null = useSelector((state: IStore) => state.pageData?.data['mega-menu'] || null);
  const importantMenu: IImportantMenu | undefined = useSelector((state: IStore) => state.pageData?.data['important-menu']);

  return mapMegaMenu(categoryWrapped, megaMenuStrapi, importantMenu, pages);
};

const findSexPage = (pages: IPageReference[], sexType: EGender) => {
  return pages?.find((page) => page.type === 'sex' && page.filter?.sexId === sexType);
};

const mapMegaMenu = (
  categoryWrapped,
  megaMenuStrapi: null | IMegaMenuLinkDataObject[],
  importantMenu: IImportantMenu | undefined,
  pages: IPageReference[],
): IMegaMenuData => {

  const manPage = findSexPage(pages, EGender.man);
  const manStrapiSection = mapStrapiToStore(megaMenuStrapi, importantMenu, EGender.man);
  const manManagementSection = mapManagementToStore(categoryWrapped, EGender.man, manPage?.url || manStrapiSection.page);

  const womenPage = findSexPage(pages, EGender.women);
  const womenStrapiSection = mapStrapiToStore(megaMenuStrapi, importantMenu, EGender.women);
  const womenManagementSection = mapManagementToStore(categoryWrapped, EGender.women, womenPage?.url || womenStrapiSection.page);

  const kidsStrapiSection = mapStrapiToStore(megaMenuStrapi, importantMenu, EGender.girl);
  const kidsManagementSection = mapManagementToStore(categoryWrapped, EGender.girl, kidsStrapiSection.page);

  const girlsPage = findSexPage(pages, EGender.girl);
  const girlStrapiSection = mapStrapiToStore(megaMenuStrapi, importantMenu, EGender.girl);
  const girlManagementSection = mapManagementToStore(categoryWrapped, EGender.girl, girlsPage?.url || girlStrapiSection.page);

  const boysPage = findSexPage(pages, EGender.boy);
  const boyStrapiSection = mapStrapiToStore(megaMenuStrapi, importantMenu, EGender.boy);
  const boyManagementSection = mapManagementToStore(categoryWrapped, EGender.boy, boysPage?.url || boyStrapiSection.page);
  return {
    [EGender.man]: {
      brands: manStrapiSection.brands || [],
      categories: manManagementSection.categories || [],
      trending: [...manStrapiSection.popularCategories, ...manManagementSection.trending],
    },
    [EGender.women]: {
      brands: womenStrapiSection.brands || [],
      categories: womenManagementSection.categories || [],
      trending: [...womenStrapiSection.popularCategories, ...womenManagementSection.trending],
    },
    [EGender.girl]: {
      brands: girlStrapiSection.brands || [],
      categories: girlManagementSection.categories || [],
      trending: [...girlStrapiSection.popularCategories, ...girlManagementSection.trending],
    },
    kids: {
      brands: kidsStrapiSection.brands || [],
      categories: kidsManagementSection.categories || [],
      trending: [...kidsStrapiSection.popularCategories, ...kidsManagementSection.trending],
    },
    [EGender.boy]: {
      brands: boyStrapiSection.brands || [],
      categories: boyManagementSection.categories || [],
      trending: [...boyStrapiSection.popularCategories, ...boyManagementSection.trending],
    },
  };
};

export const generateUrlForManagementCategory = (item: IWrappedCategoryMapped, page: string | null, parent?: IWrappedCategoryMapped): string | null => {
  if (page) {
    if (item?.seoAttributes?.seoUrlSlug) {
      if (parent?.seoAttributes?.seoUrlSlug) {
        return page + '/' + parent.seoAttributes.seoUrlSlug + '/' + item.seoAttributes.seoUrlSlug;
      }
      return page + '/' + item.seoAttributes.seoUrlSlug;
    }
    return addParamsToUrl(page, { [EProductsListFilter.categoryId]: item.categoryId });
  }
  return null;
};

const mapManagementCategory = (item: IWrappedCategoryMapped, page: string | null, parent?: IWrappedCategoryMapped, genderSexId?: number): IMegaMenuCategory => {

  const genderImage = item?.mobileImages ? item?.mobileImages.find((mobileImage) => Number(mobileImage.sexId) === Number(genderSexId)) : null;
  const img = genderImage ? genderImage.pictureUrl : item.mobilePictureUrl;

  return ({
    children: item?.children?.map((child) => mapManagementCategory(child, page, item, genderSexId)) || null,
    important: item.important,
    label: item.categoryLocal,
    mobilePictureUrl: img || null,
    url: generateUrlForManagementCategory(item, page, parent),
  });
};

const mapManagementToStore = (
  categoryWrapped: IWrappedCategorySectionMapped[],
  genderSexId: number,
  page: string | null,
) => {
  const categoryWrappedGenderSection: IWrappedCategorySectionMapped | undefined = categoryWrapped?.find(
    (categoryWrappedSection) => categoryWrappedSection.sexId === genderSexId,
  );
  const trending: IMegaMenuCategory[] =
    categoryWrappedGenderSection?.popularCategories.map((item) => mapManagementCategory(item, page, undefined, genderSexId)) || [];
  const categories: IMegaMenuCategory[] =
    categoryWrappedGenderSection?.categories.map((item) => mapManagementCategory(item, page, undefined, genderSexId)) || [];
  return { categories, trending };
};

const mapStrapiToStore = (
  megaMenuStrapi: IMegaMenuLinkDataObject[] | null,
  importantMenu: IImportantMenu | undefined,
  genderSexId: number,
) => {
  const genderType = mapGenderIdToType(genderSexId);
  const categoryGenderSection: IMegaMenuLinkDataObject | undefined =
    (genderType &&
      megaMenuStrapi?.find(
        (megaMenuStrapiSection: IMegaMenuLinkDataObject) => megaMenuStrapiSection.dropdownType === genderType,
      )) ||
    undefined;
  const page = categoryGenderSection?.dropdownItems?.page || null;
  const dropdownType = categoryGenderSection?.dropdownType || EGenderType.kids;
  const importantItems: IImportantMenuItem[] = dropdownType && importantMenu ? importantMenu[dropdownType] : [];
  const popularCategories: IMegaMenuCategory[] =
    importantItems.map(({ icon, name, url }) => ({
      important: true,
      label: name,
      mobilePictureUrl: icon,
      url,
    })) || [];

  const brandsType = (dropdownType && mapGenderTypeToBrandType(dropdownType)) || null;
  const brandsItems: IImportantMenuItem[] = brandsType && importantMenu ? importantMenu[brandsType] : null;
  const brands = brandsItems
    ? brandsItems.map(({ name, url }) => ({
      translation: name,
      url,
    }))
    : [];
  return { brands, page, popularCategories };
};
